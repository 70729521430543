import React from "react";
import styled from "styled-components";
import {
  Head,
  OtherContainer,
  SectionTitle,
  SmallTitle,
} from "../GlobalStyles";
import { IoIosCall } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { Helmet } from "react-helmet";

const PageStyles = styled.div`
  .banner-container {
    margin-bottom: 3rem;

    .banner-img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }

  .text-container {
    text-align: left;
    margin-bottom: 2rem;
  }

  .inner-container {
    padding: 1.5rem;
    background-color: rgba(0, 0, 0, 0.035);
    text-align: left;
    margin-bottom: 1.5rem;

    .flex-container {
      display: flex;

      .button-wrap {
        margin-right: 2rem;

        @media screen and (max-width: 480px) {
          margin-right: 1rem;
        }

        .con-link {
          color: #000;
          text-decoration: none;

          &:hover {
            color: red;
            cursor: pointer;
          }
        }

        .con-icon {
          margin-right: 0.2rem;
          font-size: 1.5rem;
          margin-top: -0.2rem;

          &:hover {
            color: red;
            cursor: pointer;
          }

          @media screen and (max-width: 480px) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
`;

const Airport = () => {
  return (
    <PageStyles>
      <OtherContainer>
        <Helmet>
          <title>Henna Art at Indira Gandhi Airport | Convenient Mehndi Services On-Demand</title>
          <link
            rel="canonical"
            href="http://www.vinaymehandiartist.co.in/mehndi-on-airport"
          />
          <meta
            name="descriptions"
            content="Enjoy beautiful henna designs at Indira Gandhi Airport with our on-demand mehndi services. Book your appointment easily via phone or email and experience exquisite mehndi art while waiting. Ideal for travelers and visitors."
          />
        </Helmet>
        <div className="banner-container">
          <img src="/images/igi airport.webp" alt="" className="banner-img" />
        </div>
        <div className="text-container">
          <Head>24x7 Henna Art Service at Indira Gandhi Airport</Head>
          <p>
            Discover the timeless beauty of henna art with our 24x7 service at
            Indira Gandhi Airport, Delhi. Whether you're traveling for business,
            leisure, or on a layover, our skilled henna artists are available
            around the clock to adorn your hands with exquisite mehndi designs.
            We offer a unique and convenient experience, allowing you to relax
            in the airport's seating area or lobby while we create beautiful
            patterns tailored to your preferences.
          </p>
          <p>
            From intricate bridal designs to simple, elegant motifs, our artists
            can cater to all styles and occasions. Booking our service is
            easy—whether you prefer to schedule an appointment in advance or
            decide on the spot, we're here to accommodate your needs at any time
            of day or night.
          </p>
          <p>
            Our service is perfect for those seeking to add a special touch to
            their journey or create a lasting memory before departing. With a
            commitment to quality and hygiene, we use only natural, high-quality
            henna, ensuring a safe and satisfying experience. Contact us via
            phone, email, or WhatsApp to book your appointment and let us bring
            the artistry of henna to you, anytime, at Indira Gandhi Airport.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="inner-container">
              <SmallTitle>Efficient Use of Time</SmallTitle>
              <p>
                Make the most of your time at Indira Gandhi Airport with our
                efficient henna art service. Our artists are skilled at creating
                stunning designs quickly and accurately, allowing you to enjoy a
                beautiful mehndi experience without disrupting your schedule.
                Whether you have a short layover or a long wait, we ensure you
                receive top-quality henna art while efficiently managing your
                time at the airport.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner-container">
              <SmallTitle>Commitment to Hygiene</SmallTitle>
              <p>
                We prioritize your safety by adhering to the highest hygiene
                standards. Our artists use only natural, high-quality henna, and
                all tools are thoroughly sanitized before each use. We ensure a
                clean and comfortable environment for every client, giving you
                peace of mind while enjoying our beautiful henna designs. Rest
                assured, your health and satisfaction are our top priorities.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner-container">
              <SmallTitle>Pricing Information</SmallTitle>
              <p>
                Our pricing is designed to be flexible, catering to a variety of
                design preferences and budgets. We offer competitive rates for
                simple designs and custom packages for more intricate patterns.
                Pricing may vary depending on the complexity of the design and
                the number of people in your group. Contact us for a detailed
                quote and to discuss any special requests or group discounts.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner-container">
              <SmallTitle>How to Book Appointment</SmallTitle>
              <p>
                Booking your henna appointment is easy! Simply call or WhatsApp
                us at 9958273689 or email us at vnymehndi@gmail.com. You can
                also contact us by clicking the buttons below. Whether you
                prefer advance booking or on-demand service at Indira Gandhi
                Airport, we're here to make your experience seamless and
                enjoyable.
              </p>
              <div className="flex-container">
                <div className="button-wrap">
                  <a
                    href="tel:+919958273689"
                    className="con-link call-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoIosCall className="con-icon" />
                    Call Us
                  </a>
                </div>
                <div className="button-wrap">
                  <a
                    href="https://wa.me/919958273689"
                    className="con-link whatsapp-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiWhatsappFill className="con-icon" />
                    Message Us
                  </a>
                </div>
                <div className="button-wrap">
                  <a
                    href="mailto:vnymehndi@gmail.com"
                    className="con-link email-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MdEmail className="con-icon" />
                    Email Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherContainer>
    </PageStyles>
  );
};

export default Airport;
