import React, { useState } from "react";
import { MainContainer, SmallTitleLeft } from "../GlobalStyles";
import VideoData from "../components/VideoData";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const PageStyles = styled.div`
  .text-container {
    margin-bottom: 2rem;
    text-align: left;
  }

  .title-wrap {
    margin-bottom: 3rem;
  }

  .iframe-container {
    margin-bottom: 2rem;

    iframe {
      width: 100%;
      height: 300px;
    }

    .iframe-title {
      text-align: left;
      font-size: 0.9rem;
      line-height: 1.2rem;

      @media screen and (max-width: 480px) {
        font-size: 0.8rem;
      }
    }
  }
`;

const Videos = () => {
  const [items, setItems] = useState(VideoData);

  return (
    <PageStyles>
      <MainContainer>
        <Helmet>
          <title>
            Henna Art Video Gallery | Beautiful Designs & Captivating Moments |
            Vinay Arts
          </title>
          <link
            rel="canonical"
            href="http://www.vinaymehandiartist.co.in/video-gallery"
          />
          <meta
            name="descriptions"
            content="Discover our Henna Art Video Gallery showcasing intricate designs and artistic creations. Watch videos of our skilled artists in action, and get inspired by our unique henna patterns. Perfect for event planning or personal inspiration, explore how we bring traditional art to life with every design."
          />
        </Helmet>
        <SmallTitleLeft>
          Explore Our Henna Art Video Gallery :
          <span>
            {" "}
            Stunning Designs and Captivating Moments from Our Services
          </span>
        </SmallTitleLeft>
        <div className="text-container">
          Welcome to our Henna Art Video Gallery! Here, you'll find a curated
          collection of videos showcasing the intricate beauty of our henna
          designs. Watch as our skilled artists transform simple lines into
          stunning works of art, each design reflecting the rich cultural
          heritage and creativity that define our craft. Whether you're looking
          for inspiration for your next event or simply appreciate the artistry
          of henna, these videos offer a glimpse into the detailed process
          behind our creations. Explore our gallery and see the magic of henna
          unfold before your eyes.
        </div>
        <div className="row">
          {items.map((slide, id) => {
            return (
              <div className="iframe-container col-md-4 col-sm-6 col-12">
                <iframe
                  className="video"
                  title="Youtube player"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/${slide.videoId}?autoplay=0`}
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
                <div className="iframe-title">{slide.caption}</div>
              </div>
            );
          })}
        </div>
      </MainContainer>
    </PageStyles>
  );
};

export default Videos;
